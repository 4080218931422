<template>
  <section id="space">
    <br />
    <br />
  </section>
</template>
<script>
export default {
  name: "SectionSpace",
};
</script>
